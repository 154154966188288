import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/actions';

import Header from '../../components/navigation/Header';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import reportsLoader from '../../public/icons/loader.png';
import '../admin/NoticeBoard.css';
import Calendar from '../../public/icons/calendar2.svg';



const NoticeBoardListLearner = (props) => {
    const { getNoticeBoardList, createNoticeBoard, deleteNoticeBoardList, getLearnerProfile } = props;

    const [noticeBoardList, setNoticeBoardList] = useState([]);
    const [title, setTitle] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [description, setDescription] = useState('');
    const [centerId, setCenterId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [noticeUrl, setNoticeUrl] = useState('')
    const [noticeType, setNoticeType] = useState('')
    const [isPin, setIsPin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [noticeToDelete, setNoticeToDelete] = useState(null);
    const [noticeId, setNoticeId] = useState(0);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');

    const [classList, setClassList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [getProfileData, setProfileData] = useState([]);

    const getCenterID = localStorage.getItem("center_id");





    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await getLearnerProfile();
                setProfileData(response.data);
                setSelectedClass(response?.data?.classs?.id); // Set the class_id
                setSelectedBatch(response?.data?.batches[0]?.id);
            } catch (error) {
                console.error("Failed to fetch class list:", error);
            }
        };
        fetchProfileData();
    }, []);


    useEffect(() => {
        if (selectedClass && selectedBatch) {
            const fetchNoticeBoardList = async () => {
                try {
                    const response = await getNoticeBoardList(getCenterID, selectedClass, selectedBatch);
                    if (response && response.data) {
                        setNoticeBoardList(response.data);
                    } else {
                        console.error('Unexpected response structure:', response);
                    }
                } catch (error) {
                    console.error('Failed to fetch noticeboard list:', error);
                }
            };
            fetchNoticeBoardList();
        }
    }, [getNoticeBoardList, getCenterID, selectedClass, selectedBatch]);




    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return date.toLocaleDateString('default', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    }








    return (
        <div style={{
            overflow: 'hidden',
            width: 'calc(100vw - 150PX)',
            height: 'calc(100vh - 0px)',
            position: 'fixed',
            top: '0px',
            right: '0px',
            borderRadius: '10px',
            background: '#ffffff',
            color: '#ffffff',
            padding: '30px 40px'
        }}>
            <Header placeholder="Search classes" />
            <div className="main-part">
                <div className="sub-head">
                    <div className="heading-part1">
                        <div className="heading">
                            <h1>Noticeboard</h1>
                        </div>
                    </div>
                    <div className="heading-part2">
                        {/* <span className="add-new-button" onClick={() => setIsModalOpen(true)}>
                            <img src={Add} alt="Add" /> Add New
                        </span> */}
                    </div>
                </div>





                <div className="noticeboard-list" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', padding: '20px 0' }}>

                    {noticeBoardList.length === 0 ? (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={reportsLoader} alt="Loading..." />
                        </div>
                    ) : (
                        noticeBoardList?.map((notice, index) => (
                            <div key={index} className="card" style={{ width: '400px', border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden' }}>
                                <div className="card-content" style={{ padding: '15px' }}>
                                    <h3 style={{ marginBottom: '10px' }}>{notice?.title}</h3>
                                    <p style={{ marginBottom: '10px', color: 'black' }}>{notice?.description}</p>
                                    <div className="date-container" style={{ display: 'flex', flexDirection: 'row', gap: '5px', marginBottom: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: '#2DA77D' }}>
                                            <img src={Calendar} alt="Calendar" style={{ height: '16px', width: '16px', color: '#2DA77D' }} />
                                            <span>{formatDateTime(notice?.start_date_time)}</span>
                                            <p style={{ color: '#2DA77D' }}>To</p>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', color: '#2DA77D' }}>
                                            {/* <img src={Calendar} alt="Calendar" style={{ height: '16px', width: '16px' }} /> */}
                                            <span>{formatDateTime(notice?.end_date_time)}</span>
                                        </div>
                                    </div>
                                    <div className="card-actions" >
                                        <a href={notice.file} target="_blank" rel="noopener noreferrer" style={{
                                            height: '40px',
                                            width: '80px',
                                            backgroundColor: '#2DA77D',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding: '5px 10px',
                                            cursor: 'pointer',
                                            textDecoration: 'none',
                                        }}>
                                            View Notice
                                        </a>

                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>


        </div>
    );
};

const mapStateToProps = (state) => ({
    // Map your state to props if needed
});

const mapDispatchToProps = (dispatch) => {
    const { getNoticeBoardList,
        deleteNoticeBoardList, getClassList, getSectionList2, getSubjectList2, getLearnerProfile } = actions;
    return {

        getNoticeBoardList: (center_id, class_id, batch_id) => dispatch(getNoticeBoardList(center_id, class_id, batch_id)),
        deleteNoticeBoardList: (id) => dispatch(deleteNoticeBoardList(id)),
        getClassList: () => dispatch(getClassList()),
        getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
        getSubjectList2: (center_id, class_id) => dispatch(getSubjectList2(center_id, class_id)),
        getLearnerProfile: () => dispatch(getLearnerProfile()),


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticeBoardListLearner);