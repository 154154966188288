import React from 'react';
import Videojs from './video';
import axios from 'axios';
import common from '../../../common';
import ReactPlayer from 'react-player/youtube'
import 'video.js/dist/video-js.css';

class VideoPlayer extends React.Component {
	constructor(props) {
    super(props);
		this.state = {
			url: '',
			token: this.props.location.search.split('&')[0].split('=')[1],
     	content_id: this.props.location.search.split('&')[1].split('=')[1],
     	loading: true,
      is_youtube:false,
      youtubeString:null
    }
  }

  componentDidMount() {
		axios.get(`${common.apiBase}/${common.contentDetails}${this.state.content_id}/`,
			{headers: {
			  'Authorization': 'token ' + this.state.token}
			}).then(res => {
				this.videoJsOptions = {
  				autoplay: true,
          preload: 'auto',
  				responsive: true,
  				controls: true,
  				controlBar: { volumePanel: { inline: true }, pictureInPictureToggle: false },
  				html5: {
            hls: {
              overrideNative: true, // add this line
            },
            nativeVideoTracks: true
        	},
  				fluid: true,
  				playbackRates: [0.5, 1, 1.25, 1.5, 2],
  				techCanOverridePoster: false,
  				textTrackSettings: true,
  				sources: [
  				  {
  				    src: common.addFileUrl(res.data.data.content.file_details[0].file_url),
  				    type: this.checkType(res.data.data.content.file_details[0].file_url),
  				  },
  				]
  			}
      	
        if(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/.test(res.data.data.content.file_details[0].file_url)) {
      
          let stringUrl = res.data.data.content.file_details[0].file_url.split("=")[1] ;
          this.setState({is_youtube :1,url:res.data.data.content.file_details[0].file_url,loading:false})
          //setInterval(this.addTime,1000)
        }else{
          this.setState({ url: common.addFileUrl(res.data.data.content.file_details[0].file_url), loading: false })
        }

    }).catch(err => { console.error('error', err.message) })
	}

	checkType(url) {
		if(url.substring(url.length - 4, url.length).toLowerCase() === 'm3u8') {
			return 'application/x-mpegURL'
		}
		else {
			return 'video/mp4'
		}
	}
    
	render() {
		if(this.state.loading === true) {
			return (
				<div>
				</div>
			)
		}
		else {
      if(this.state.is_youtube === 1) {
        return (
          <ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' } } }} className="video-container2" ref={this.ref} url={this.state.url} controls={true} volume={1} muted={true} playbackRate={1} playing={this.state.playing} onLoaded={this.handlePlay}/>
        )
      }
      else{
        return (
          <div className="video-container2">
            <Videojs {...this.videoJsOptions} />
          </div>
        );
      }
		}
	}
}

export default VideoPlayer;